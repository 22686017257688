<template>
    <div class="extra-services">
        <ResidentHeader page-title="Services complémentaires" />
        <div class="services">
          <HCard
            hoverZoom
            type="free"
            class="service-card"
            v-for="(service, serviceIndex) in services"
            :key="serviceIndex"
            :to="getServiceLink(service)"
          >
            <div class="banner">
              <div class="splash">
                <img class="background" :src="service.bannerUrl" border="0" />
              </div>
              <div class="avatar">
                <span class="border"></span>
                <img class="photo" :src="service.photoUrl" border="0" />
              </div>
            </div>
            <div class="content">
              <div class="content-title">{{ service.title }}</div>
              <div class="content-description">{{ service.description }}</div>
            </div>
          </HCard>
        </div>
    </div>
</template>

<script>
import Routes from '@/constants/routes'

import Http from '@/http'

import ToastMixin from '@/mixins/toasts'
import ResidentHeader from '@/views/residents/components/ResidentHeader.vue'

import {
  HBtn,
  HCard
} from '@happytal/bo-ui-library'

export default {
  name: 'ExtraServices',
  mixins: [
    ToastMixin
  ],
  components: {
    HBtn,
    HCard,
    ResidentHeader
  },
  data () {
    return {
      dataServices: []
    }
  },
  computed: {
    currentInstititionId () {
      return this.$store.getters['dataUser/getInstitutionId']()
    },
    services () {
      return _.map(this.dataServices, (service) => {
        return {
          id: service.id,
          title: service.title,
          category: _.get(service, 'category.name', ''),
          photoUrl: _.get(service, 'partners.logo.url', ''),
          bannerUrl: _.get(service, 'banner.url', ''),
          description: service.short_description
        }
      })
    }
  },
  methods: {
    getServiceLink (service) {
      return {
        name: Routes.ExtraService,
        params: {
          service_id: service.id
        }
      }
    },
    getServices () {
      Http.apis.strapi.get(`/extra-services?institutions.institution_id=0&institutions.institution_id=${this.currentInstititionId}`)
      .then((response) => {
          this.dataServices = _.get(response, 'data', null)
      })
      .catch((error) => {
          this.$_showErrorToast(`Une erreur s'est produite (${error}).`)
      })
    }
  },
  mounted () {
    this.getServices()
  }
}
</script>

<style lang="scss" scoped>
.extra-services {

  .services {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 38px;
    row-gap: 40px;

    .service-card {
      display: flex;
      flex-direction: column;
      padding: 0;

      .banner {
        position: relative;
        background: #EFEFEF;
        border-radius: 6px 6px 0px 0px;

        .splash {
          height: 168px;
          overflow: hidden;
          border-radius: 6px 6px 0px 0px;

          .background {
            width: 100%;
            border-radius: 6px 6px 0px 0px;
          }
        }
        .avatar {
          position: absolute;
          left: 24px;
          bottom: -43px;
          width: 85px;
          height: 85px;
          background: white;
          border: 2px solid #C4C4C4;
          border-radius: 43px;
          white-space: nowrap;
          text-align: center;
          overflow: hidden;

          .border {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
          }
          .photo {
            vertical-align: middle;
            max-height: 80px;
            max-width: 80px;
          }
        }
      }
      .content {
        flex-grow: 1;
        position: relative;

        .content-title {
          padding: 50px 20px 20px 20px;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 29px;
          text-align: center;
          color: black;
        }
        .content-description {
          padding: 0px 40px 40px 40px;
          font-style: normal;
          font-size: 16px;
          line-height: 18px;
          text-align: justify;
          color: black;
        }
      }
    }
  }
}
</style>
